import { GetQuotePayload } from 'api/insurance'
import {
  isCruise,
  isValidCountry,
  isValidRegion,
  overrideInsuranceParams,
  ValidCountry,
  ValidRegion,
} from 'checkout/lib/utils/insurance/payload'
import { checkoutAccommodationGroupingKey, checkoutAccommodationOfferView, getAccommodationItems } from 'checkout/selectors/view/accommodation'
import { excludeNullOrUndefined } from 'checkout/utils'
import createSelector from 'lib/web/createSelector'
import {
  PRODUCT_ID_CANCELLATION_AND_BAGGAGE,
  PRODUCT_ID_COMPREHENSIVE,
  PRODUCT_ID_OVERSEA_MEDICAL,
  PROVIDER_COVER_GENIUS,
} from 'constants/insurance'
import moment from 'moment'
import { ISO_DATE_FORMAT } from 'constants/dateFormats'
import { groupBy, max, nonNullable, sum, unique } from 'lib/array/arrayUtils'
import { getTotalsWithoutInsurance } from 'checkout/selectors/payment/checkout'
import { getFlightItemsView } from 'checkout/selectors/view/flights'
import { CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_CRUISE, CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_TOUR_V1, CHECKOUT_ITEM_TYPE_TOUR_V2, CHECKOUT_ITEM_TYPE_VILLA } from 'constants/checkout'
import { getTourV2IdForCheckoutItem } from 'lib/checkout/checkoutUtils'
import { getInsuranceEnabledCheckoutItems } from '../view/accommodation'
import { areOccupantsEqual, countOccupants, countTravellersAsOccupants, getOccupanciesFromReservations, mergeOccupants } from 'lib/offer/occupancyUtils'
import { getFullOffers } from 'selectors/offerSelectors'
import { getItineraryCountries } from 'checkout/lib/utils/cruises/itinerary'
import { isBookingProtectionAvailable, isBookingProtectionEnabled } from './bookingProtection'
import { BOOKING_PROTECTION_INTERNATIONAL_EXPERIMENT_REGIONS } from 'constants/config/region'
import { isSpoofed } from 'selectors/featuresSelectors'
import getObjectKey from 'lib/object/getObjectKey'
import { flattenObjectKeys } from 'lib/object/objectUtils'

const getDestCountriesFromCart = createSelector(
  getAccommodationItems,
  getFullOffers,
  (items, offers) => {
    const allOffersLoaded = items.every(item => {
      if ([CHECKOUT_ITEM_TYPE_BEDBANK, CHECKOUT_ITEM_TYPE_LE_HOTEL, CHECKOUT_ITEM_TYPE_VILLA].includes(item.itemType)) {
        return !!offers[item.offerId]
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2) {
        return !!offers[getTourV2IdForCheckoutItem(item)]
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1 && !!offers[item.offerId]) {
        const offer = offers[item.offerId] as App.TourOffer | undefined
        if (offer?.holidayTypes?.includes('Cruises')) {
          return !!offers[item.offerId]
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
        return !!offers[item.offerId]
      }
    })
    if (!allOffersLoaded) { return [] }

    return unique(items.map(item => {
      if (item.itemType === CHECKOUT_ITEM_TYPE_BEDBANK) {
        const offer = offers[item.offerId] as App.BedbankOffer
        return offer ? [offer.property.address.countryName] : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_LE_HOTEL) {
        const offer = offers[item.offerId] as App.Offer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_VILLA) {
        const offer = offers[item.offerId] as App.VillaOffer
        return offer ? (offer.insuranceCountries?.length ? offer.insuranceCountries : [offer.property?.geoData.country]) : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V2) {
        const offer = offers[getTourV2IdForCheckoutItem(item)] as Tours.TourV2Offer
        const departure = offer?.departures[item.purchasableOption.fkDepartureId]
        return departure?.isGuaranteed ? offer.variations[departure.fkVariationId].countriesVisited : []
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_TOUR_V1) {
        const offer = offers[item.offerId] as App.TourOffer
        if (offer) {
          return offer.locations.length > 0 ? offer.locations : [offer.locationHeading]
        }
      } else if (item.itemType === CHECKOUT_ITEM_TYPE_CRUISE) {
        const offer = offers[item.offerId] as App.CruiseOffer
        if (offer) {
          return getItineraryCountries(offer)
        }
      }
    }).flat().filter(excludeNullOrUndefined).filter(isValidCountry))
  },
)

export const getInsuranceCoverAmountFromCart = createSelector(
  getTotalsWithoutInsurance,
  (totals): number | undefined => {
    if (totals.hasRequiredData) {
      const {
        paidPrice = 0,
        price = 0,
        surcharge = 0,
        otherFees = {},
      } = totals.data
      return paidPrice + price + surcharge + sum(Object.values(otherFees))
    }
  },
)

const getInsuranceDatesFromCart = createSelector(
  checkoutAccommodationOfferView,
  getFlightItemsView,
  (accommodationViews, flightViews) => {
    if (!accommodationViews.hasRequiredData || !flightViews.hasRequiredData) { return null }

    const accommodationDates = accommodationViews.data.map(item => ({
      startDate: item.startDate,
      endDate: item.endDate,
    }))

    const flights = flightViews.data.map(flightView => [
      ...flightView.departing?.journeyFlight.flights || [],
      ...flightView.returning?.journeyFlight.flights || [],
    ]).flat()

    const flightDates = flights.map(flight => ({
      startDate: flight.departingDate,
      endDate: flight.arrivalDate,
    }))

    const dates = [...accommodationDates, ...flightDates]

    if (dates.length) {
      return {
        startDate: moment.min(dates.map(date => moment(date.startDate, ISO_DATE_FORMAT))),
        endDate: moment.max(dates.map(date => moment(date.endDate, ISO_DATE_FORMAT))),
      }
    }
  },
)

const getInsuranceOccupantsByAccommodationGroup = createSelector(
  getInsuranceEnabledCheckoutItems,
  (items) => {
    if (items.length > 0) {
      const itemMap = groupBy(
        items, checkoutAccommodationGroupingKey)

      return Array.from(itemMap.values()).map((itemList) => {
        const occupants = itemList
          .map(item => 'occupancy' in item ? item.occupancy : null)
          .filter(excludeNullOrUndefined)
        return mergeOccupants(occupants)
      })
    }
  },
)

export const areInsuranceOccupantsConsistent = createSelector(
  getInsuranceOccupantsByAccommodationGroup,
  (occupants): boolean => {
    return occupants ? areOccupantsEqual(...occupants) : true // if no occupants, that's consistent
  },
)

export const getOccupantsFromCartItems = createSelector(
  getInsuranceOccupantsByAccommodationGroup,
  areInsuranceOccupantsConsistent,
  (occupants, isConsistent) => {
    if (isConsistent && occupants) {
      return occupants[0]
    }
  },
)

const getQuoteRequestFromCartItems = createSelector(
  getOccupantsFromCartItems,
  getInsuranceCoverAmountFromCart,
  getInsuranceDatesFromCart,
  getDestCountriesFromCart,
  (occupants, coverAmount, dateRange, destCountries): Partial<GetQuotePayload> | null => {
    if (!occupants || !coverAmount || !dateRange || destCountries.length === 0) { return null }

    return {
      destination_countries: destCountries,
      includingCovid: true,
      total_price: coverAmount,
      trip_start_date: dateRange.startDate.format(ISO_DATE_FORMAT),
      trip_end_date: dateRange.endDate.format(ISO_DATE_FORMAT),
      travellers_details: {
        number_of_adults: occupants.adults,
        number_of_children: occupants.children ?? null,
        number_of_infants: occupants.infants ?? null,
        number_of_seniors: 0,
      },
    }
  },
)

export const getInsuranceDatesFromOrder = createSelector(
  (_state: App.State, order?: App.Order) => order,
  (order) => {
    if (order?.items.length) {
      const reservations = order.items.map(item => item.reservation).filter(excludeNullOrUndefined)
      const startDate = Math.min(...reservations.map(reservation => new Date(reservation.startDate).getTime()))
      const endDate = Math.max(...reservations.map(reservation => new Date(reservation.endDate).getTime()))
      return {
        startDate: moment(startDate).format(ISO_DATE_FORMAT),
        endDate: moment(endDate).format(ISO_DATE_FORMAT),
      }
    } else if (order?.bedbankItems.length) {
      const completedItems = order.bedbankItems.filter(item => item.status === 'completed')

      const startItem = max(completedItems, item => new Date(item.checkIn))
      const endItem = max(completedItems, item => new Date(item.checkOut))

      if (!startItem || !endItem) return

      return {
        startDate: moment(startItem.checkIn).format(ISO_DATE_FORMAT),
        endDate: moment(endItem.checkOut).format(ISO_DATE_FORMAT),
      }
    } else if (order?.tourItems.length) {
      const completedItems = order.tourItems.filter(item => item.status === 'completed')

      const startItem = max(completedItems, item => new Date(item.tour.startDate))
      const endItem = max(completedItems, item => new Date(item.tour.endDate))

      if (!startItem || !endItem) return

      return {
        startDate: startItem.tour.startDate,
        endDate: endItem.tour.endDate,
      }
    } else if (order?.cruiseItems.length) {
      const completedItems = order.cruiseItems.filter(item => item.status === 'completed')

      const startItem = max(completedItems, item => new Date(item.departureDate))
      const endItem = max(completedItems, item => new Date(item.arrivalDate))

      if (!startItem || !endItem) return

      return {
        startDate: startItem.departureDate,
        endDate: endItem.arrivalDate,
      }
    }
  },
)

export const getInsuranceDatesFromExistingOrder = createSelector(
  (state: App.State) => getInsuranceDatesFromOrder(state, state.checkout.cart.existingOrder),
  (result) => result,
)

export const getOccupantsFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order): App.Occupants | undefined => {
    if (order?.items.length) {
      const reservations = nonNullable(order.items.map(item => item.reservation))
      return getOccupanciesFromReservations(...reservations)
    } else if (order?.bedbankItems.length) {
      return countOccupants(order.bedbankItems.flatMap(item => item.rooms.map(room => room.occupancy)))
    } else if (order?.tourItems.length) {
      const completedItems = order.tourItems.filter(item => item.status === 'completed')
      return countTravellersAsOccupants(completedItems.flatMap(items => items.tour.travellers))
    } else if (order?.cruiseItems.length) {
      const completedItems = order.cruiseItems.filter(item => item.status === 'completed')
      return countTravellersAsOccupants(completedItems.flatMap(items => items.passengers))
    }
  },
)

export const getDestCountriesFromOrder = createSelector(
  (_state: App.State, order?: App.Order) => order,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.cruise.cruiseOffers,
  (order, offers, cruiseOffers) => {
    if (order?.items.length) {
      const offerIds = unique(order.items.map(item => item.offer.id))
      const orderOffers = offerIds.map(offerId => offers[offerId]).filter(excludeNullOrUndefined)
      if (orderOffers.length == offerIds.length) {
        return {
          countries: orderOffers.map(offer => offer.property?.geoData.country).filter(excludeNullOrUndefined),
          countryCodes: orderOffers.map(offer => offer.property?.geoData.countryCode).filter(excludeNullOrUndefined),
        }
      }
    } else if (order?.bedbankItems.length) {
      const offerIds = unique(order.bedbankItems.map(item => item.offer.id))
      const orderOffers = offerIds.map(offerId => offers[offerId]).filter(excludeNullOrUndefined) as unknown as Array<App.BedbankOffer>
      if (orderOffers.length == offerIds.length) {
        return {
          countries: orderOffers.map(offer => offer.property?.address.countryName).filter(excludeNullOrUndefined),
          countryCodes: orderOffers.map(offer => offer.property?.address.countryCode).filter(excludeNullOrUndefined),
        }
      }
    } else if (order?.tourItems.length) {
      const completedItems = order.tourItems.filter(item => item.status === 'completed')

      return {
        countries: unique(completedItems.flatMap(item => item.tour.countriesVisited)),
      }
    } else if (order?.cruiseItems.length) {
      const offerIds = unique(order.cruiseItems.map(item => item.cruiseOfferId))

      const countries = offerIds.flatMap(offerId => {
        const offer = cruiseOffers[offerId]
        if (offer) {
          return getItineraryCountries(offer)
        }
      }).filter(isValidCountry)

      return {
        countries,
      }
    }
  },
)

export const getDestCountriesFromExistingOrder = createSelector(
  (state: App.State) => getDestCountriesFromOrder(state, state.checkout.cart.existingOrder),
  (result) => result,
)

const getTotalPriceFromExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.existingOrder,
  (order): number => {
    const deposit = order?.depositDetails
    const balance = (deposit && !deposit.balance_paid_date) ? deposit.balance_amount : 0

    const payments = order?.payments || []
    const paidTotal = payments.reduce((acc, item) => acc + item.amount, 0)

    const serviceFee = order?.serviceFee?.total || 0

    return paidTotal + balance - serviceFee
  },
)

export const getQuoteRequestFromExistingOrder = createSelector(
  getInsuranceDatesFromExistingOrder,
  getOccupantsFromExistingOrder,
  getDestCountriesFromExistingOrder,
  getTotalPriceFromExistingOrder,
  (dateRange, travellerDetails, destCountries, totalPrice): Partial<GetQuotePayload> | undefined => {
    if (dateRange && travellerDetails && destCountries?.countries.length) {
      const areDestCountriesValid = destCountries.countries.every(country => isValidCountry(country))

      if (areDestCountriesValid) {
        return {
          trip_start_date: dateRange.startDate,
          trip_end_date: dateRange.endDate,
          travellers_details: {
            number_of_adults: travellerDetails.adults,
            number_of_children: travellerDetails.children || 0,
            number_of_infants: travellerDetails.infants || 0,
            number_of_seniors: 0,
          },
          destination_countries: destCountries.countries as Array<ValidCountry>,
          total_price: totalPrice,
        }
      }
    }
  },
)

export const getQuoteRequestFromChangeDates = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  (state: App.State) => state.insurance.payment?.item,
  getOccupantsFromExistingOrder,
  getDestCountriesFromExistingOrder,
  (postPurchase, insurancePaymentItem, travellerDetails, destCountries): Partial<GetQuotePayload> | undefined => {
    if (
      ['change-dates', 'change-package'].includes(postPurchase ?? '') &&
      insurancePaymentItem?.startDate &&
      insurancePaymentItem?.endDate &&
      insurancePaymentItem.coverAmount &&
      travellerDetails &&
      destCountries?.countries.length
    ) {
      return {
        trip_start_date: moment(insurancePaymentItem.startDate).format(ISO_DATE_FORMAT),
        trip_end_date: moment(insurancePaymentItem.endDate).format(ISO_DATE_FORMAT),
        travellers_details: {
          number_of_adults: travellerDetails.adults,
          number_of_children: travellerDetails.children || 0,
          number_of_infants: travellerDetails.infants || 0,
          number_of_seniors: 0,
        },
        total_price: insurancePaymentItem.coverAmount,
      }
    }
  },
)

/**
 * Build the request payload for an insurance quote
 */
export const getInsuranceQuoteRequest = createSelector(
  (state: App.State) => state.checkout.cart,
  (state: App.State) => state.offer.offers,
  (state: App.State) => state.geo.currentRegionCode,
  (state: App.State) => state.checkout.cart.currencyCode,
  getQuoteRequestFromCartItems,
  getQuoteRequestFromExistingOrder,
  getQuoteRequestFromChangeDates,
  (state: App.State) => state.checkout.cart.insurance,
  (cart, leOffer, regionCode, currencyCode, requestFromCartItems, requestFromExistingOrder, requestFromChangeDates, insuranceParameters): GetQuotePayload | null => {
    if (!requestFromCartItems && !requestFromExistingOrder && !requestFromChangeDates) { return null }
    const defaultParameters: Partial<GetQuotePayload> = {
      provider: PROVIDER_COVER_GENIUS,
      country_code: regionCode as ValidRegion,
      currency: currencyCode,
      session_id: cart.cartId,
      isCruise: !!cart.existingOrder?.cruiseItems.length || isCruise(cart.items, leOffer),
    }

    const params = {
      ...defaultParameters,
      ...requestFromExistingOrder,
      ...requestFromCartItems,
      ...requestFromChangeDates,
    } as GetQuotePayload

    return insuranceParameters ?
      overrideInsuranceParams(params, insuranceParameters) :
      params
  },
)

export const getInsuranceQuoteRequestKey = createSelector(
  getInsuranceQuoteRequest,
  (request): string | undefined => {
    return request ? getObjectKey(flattenObjectKeys(request)) : undefined
  },
)

export const getInsuranceFetchParams = createSelector(
  getInsuranceQuoteRequest,
  (request): App.Checkout.InsuranceFetchParameters | undefined => {
    if (request) {
      return {
        startDate: request.trip_start_date,
        endDate: request.trip_end_date,
        destinationCountries: request.destination_countries,
        numberOfSeniors: request.travellers_details?.number_of_seniors || 0,
      }
    }
  },
)

/**
 * Get the insurance parameters based on the checkout cart items
 *
 * @remarks
 * - Currently only supports "LE Hotels" (instant booking), "Bedbank" and "TourV2"
 */
export const isInsuranceSupportedForCartItems = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  getInsuranceEnabledCheckoutItems,
  getInsuranceDatesFromCart,
  getDestCountriesFromCart,
  (postPurchase, items, dateRange, destinationCountries): boolean => {
    return !!(
      items.length &&
      dateRange &&
      destinationCountries.length &&
      (!postPurchase || postPurchase === 'select-date')
    )
  },
)

export const isDomesticTrip = createSelector(
  (state: App.State) => state.geo.currentRegionName,
  getDestCountriesFromCart,
  getDestCountriesFromExistingOrder,
  (regionName, destCountriesFromCart, destCountriesFromOrder): boolean | undefined => {
    return destCountriesFromCart.length > 0 ?
      destCountriesFromCart.every(countryName => countryName === regionName) :
      destCountriesFromOrder?.countries.every(countryName => countryName === regionName)
  },
)

export const getCheckoutInsuranceProducts = createSelector(
  checkoutAccommodationOfferView,
  isDomesticTrip,
  (state: App.State) => state.insurance.products,
  (state: App.State) => state.insurance.fetchingProducts,
  (accommodationViews, isDomestic, insuranceProducts, isFetching): App.WithDataStatus<Array<App.InsuranceProduct>> => {
    if (isFetching || !accommodationViews.hasRequiredData) {
      return {
        hasRequiredData: false,
        data: [],
      }
    }

    // the filtering will be removed later, as the backend will return the correct products
    // tracked by ticket ENGX-786
    const data = isDomestic ?
      insuranceProducts.filter(product => product.id !== PRODUCT_ID_OVERSEA_MEDICAL && product.id !== PRODUCT_ID_COMPREHENSIVE) :
      insuranceProducts.filter(product => product.id !== PRODUCT_ID_CANCELLATION_AND_BAGGAGE)

    return {
      hasRequiredData: true,
      data,
    }
  },
)
export const isInsuranceSupportedForExistingOrder = createSelector(
  (state: App.State) => state.checkout.cart.postPurchase,
  getInsuranceDatesFromExistingOrder,
  getDestCountriesFromExistingOrder,
  (postPurchase, insuranceDates, destCountries): boolean => {
    return !!(postPurchase == 'insurance' && insuranceDates && destCountries?.countries.length)
  },
)

export const isInsuranceSupported = createSelector(
  isInsuranceSupportedForCartItems,
  isInsuranceSupportedForExistingOrder,
  isBookingProtectionEnabled,
  (state: App.State) => state.geo.currentRegionCode,
  isSpoofed,
  (isInsuranceAvailableForCartItems,
    isInsuranceAvailableForExistingOrder,
    isBookingProtectionEnabled,
    currentRegionCode,
    isSpoofed): boolean => {
    if (!isValidRegion(currentRegionCode)) return false
    if (!isInsuranceAvailableForCartItems && !isInsuranceAvailableForExistingOrder) return false
    if (isBookingProtectionEnabled && !isSpoofed) return false
    return true
  },
)

export const shouldTriggerBookingProtectionInternationalExperiment = createSelector(
  isInsuranceSupportedForCartItems,
  isBookingProtectionAvailable,
  isDomesticTrip,
  getInsuranceDatesFromCart,
  (state: App.State) => state.geo.currentRegionCode,
  isSpoofed,
  (isInsuranceAvailableForCartItems, isBookingProtectionAvailable, isADomesticTrip, insuranceDates, currentRegionCode, isSpoofed): boolean => {
    if (isSpoofed) return false
    if (!BOOKING_PROTECTION_INTERNATIONAL_EXPERIMENT_REGIONS.includes(currentRegionCode)) return false
    if (!insuranceDates?.startDate) return false
    return isInsuranceAvailableForCartItems && isBookingProtectionAvailable && !isADomesticTrip
  },
)

export const isPastHotelInsuranceCoverPeriod = createSelector(
  getInsuranceDatesFromCart,
  (state: App.State) => state.checkout.cart.existingOrder,
  (state: App.State) => state.checkout.cart.existingOrder?.insuranceItems,
  (state: App.State) => state.checkout.cart.postPurchase,
  (newDatesFromCart, existingOrder, insuranceItems, postPurchase): boolean => {
    const insuranceItem = insuranceItems?.find(item => item.status === 'completed')

    if (
      !existingOrder ||
      !insuranceItem ||
      !newDatesFromCart?.endDate ||
      !['change-dates', 'change-package'].includes(postPurchase ?? '')
    ) {
      return false
    }
    return moment(existingOrder?.createdAt).add(1, 'year').isBefore(newDatesFromCart?.endDate)
  },
)
